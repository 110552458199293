@import url('https://fonts.googleapis.com/css2?family=Ubuntu:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&display=swap');

/* ANIMATIONS from https://codepen.io/kootoopas/pen/reyqg*/
@-webkit-keyframes bg-scrolling-reverse {
  100% {
    background-position: 50px 50px;
  }
}
@-moz-keyframes bg-scrolling-reverse {
  100% {
    background-position: 50px 50px;
  }
}
@-o-keyframes bg-scrolling-reverse {
  100% {
    background-position: 50px 50px;
  }
}
@keyframes bg-scrolling-reverse {
  100% {
    background-position: 50px 50px;
  }
}

@-webkit-keyframes bg-scrolling {
  0% {
    background-position: 50px 50px;
  }
}
@-moz-keyframes bg-scrolling {
  0% {
    background-position: 50px 50px;
  }
}
@-o-keyframes bg-scrolling {
  0% {
    background-position: 50px 50px;
  }
}
@keyframes bg-scrolling {
  0% {
    background-position: 50px 50px;
  }
}

/* TOP */
.App {
  text-align: center;
  background-color: #7db5ff;
  width: 100vw;
  /* -webkit-background-size: cover; */
  /* -moz-background-size: cover; */
  /* -o-background-size: cover; */
  /* background-size: contain; */
  /* background-position: center;
  background-attachment: fixed;
  background-repeat: no-repeat; */
  font-family: 'Ubuntu', sans-serif;
  color: #14181d;
}
.page {
  min-height: 100vh;
  display: flex;
}
/* NAV BAR */
.nav-container {
  display: flex;
  margin: 0;
  padding: 0;
}
.nav-bar {
  position: fixed;
  padding: 0;
  z-index: 10;
  width: 100vw;
  background-color: #14181d;
  border-bottom: 0.1px outset #14181d;
}
.navbar-nav {
  display: flex;
  justify-content: center;
  width: 100vw;
}
.navbar-dark .navbar-nav .nav-link {
  font-size: 2vw;
  margin: 0 4vw;
  font-weight: bold;
  color: #7db5ff;
  padding-bottom: 8px;
}
.navbar-dark .navbar-nav .nav-link:hover {
  color: #e75151;
}
.navbar-dark .navbar-nav .nav-link.active {
  background-color: #14181d;
  color: #e75151;
  border-bottom: 5px solid #e75151;
  border-radius: 5px;
  padding-bottom: 3px;
}
.nav-contact {
  display: flex;
  margin: 2vw;
  align-items: center;
  text-decoration: none;
  color: #14181d;
  font-size: 2rem;
}
.nav-contact:hover {
  color: #e75151;
}
.nav-contact:active {
  color: #e75151;
}
.number {
  display: flex;
  margin: 2vw;
  align-items: center;
  color: #14181d;
  font-size: 2rem;
}
.nav-contact > i {
  width: 2em;
}
.number > i {
  width: 2em;
}

/* INTRODUCTION */
.introduction {
  background: url('./tiles.png') repeat 0 0;
  flex-direction: column;
  align-items: center;
}
.name {
  font-size: 6vw;
  font-weight: bold;
}
.sub-name {
  border-bottom: #14181d outset 2px;
  padding-bottom: 0.8em;
  font-size: 3.54vw;
}
.name-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20vh;
  width: 80vw;
}
.intro-links {
  display: flex;
  justify-content: center;
  align-items: center;
}
.intro-resume {
  display: flex;
  padding: 0;
  margin: 2vw;
  text-decoration: none;
  color: #14181d;
  font-size: 2rem;
}
.intro-resume:hover {
  color: #e75151;
}
.intro-resume-container {
  display: flex;
  align-items: center;
}
.intro-resume-container > p {
  font-weight: bold;
  font-size: 2rem;
  margin: 0 0.5em 0 0.5em;
}
.introduction-text {
  display: flex;
  flex-direction: column;
  max-width: 750px;
}
.introduction-text > p {
  font-weight: 500;
  font-size: 1.5rem;
}

/* PROJECTS */
.projects {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 10vh;
}
.projects-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: center;
  max-width: none;
}
.projects-text {
  display: flex;
  flex-direction: column;
  max-width: 638px;
  margin-top: 10vh;
  margin-bottom: 2vh;
  text-align: left;
}
.projects-text> p {
  font-weight: 500;
  font-size: 1.5rem;
  margin: 0
}
/* ABOUT */
.about {
  flex-direction: column;
  align-items: center;
  margin-bottom:10vh;
}
.about-text {
  display: flex;
  flex-direction: column;
  max-width: 750px;
  margin-top: 10vh;
  margin-bottom: 5vh;
  text-align: left;
}
.about-text > p {
  font-weight: 500;
  font-size: 1.5rem;
  margin: 0
}
/* CONTACT */
.contact-container {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  height: 60vh;
}
.contact {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding-top: 15vh;
}
.thanks {
  display: flex;
  flex-direction: column;
  align-self: flex-end;
}
/* PROJECT CARD AND OVERLAY */
.project-card {
  width: 80vw;
  max-width: 40em;
  margin: 10px;
  border: 1px outset #1f4c69;
  color: #f2f2f2;
}
.project-card:hover {
  cursor: pointer;
}
.card-img-overlay {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  padding: 20px;
  background-color: rgba(16, 39, 54, 0.9);
  color: #f2f2f2;
  font-size: 1rem;
}
.card-img-overlay > .card-title {
  font-size: 2rem;
}

/* PROJECT MODAL */
.modal-dialog {
  max-width: 800px !important;
}
.modal-content {
  background-color: rgba(16, 39, 54, 0.97);
  border-color: #1f4c69;
  color: #7db5ff;
}
.modal-header,
.modal-footer {
  border-color: #1f4c69;
}
.modal-header {
  display: flex;
}
.modal-header > p {
  min-width: 8em;
  display: flex;
  justify-content: center;
  font-family: 'Ubuntu', sans-serif;
  font-size: 3em;
  font-weight: 700;
  margin: auto 0;
}
.modal-body > p {
  font-size: 1.5em;
}
.btn-close:focus {
  outline: 0;
  box-shadow: none;
  opacity: 1;
}
.icon-container {
  display: flex;
  justify-content: space-evenly;
  font-size: 6vw;
}

.modal-links {
  display: flex;
  flex-direction: column;
  height: inherit;
}
.modal-links > a {
  text-decoration: none;
  font-size: 1em;
  color: #7db5ff;
}
.modal-links > a:hover {
  text-decoration: none;
  color: #e75151;
}
.modal-links > a > i {
  margin: 0 1em 0 3vw;
}
.btn-close {
  color: #7db5ff;
}
.btn-close:hover {
  color: #7db5ff;
}
.page-title {
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: #14181d outset 2px;
  padding: 0.5em 0;
  margin-top: 15vh;
  width: 100vw;
  color: #7db5ff;
  background-color: #1f4c69;
}
.page-title > h2 {
  font-size: 5vw;
  font-weight: 700;
  margin: 0;
}
/* SKILLS */
.about-container {
  width: 80vw;
  max-width: 1000px;
}

.skills-header {
  border: #14181d outset 2px;
  font-weight: 700;
  background: url('./tiles.png') repeat 0 0;
}
.skills-row {
  display: flex;
  flex-wrap: wrap;
  text-align: start;

  border: #14181d outset 2px;
  background: url('./tiles.png') repeat 0 0;
  padding: 0 0;
  margin: 1em 0;
}
.skills-row > p {
  font-size: 2em;
  padding-left: 1em;
  margin: 0;
}
.skills-container {
  width: 80vw;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  padding: 0;
}
.skills-row > p {
  font-weight: 700;
}
.skill-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 1rem;
  margin: 1em;
}
.skill-card > i {
  font-size: 6rem;
}
.footer {
  display: flex;
  border: #14181d outset 2px;
  height: 10em;
  width: 10em;
  font-weight: 700;
  background: url('./tiles.png') repeat 0 0;
  -webkit-animation: bg-scrolling-reverse 1.92s infinite; /* Safari 4+ */
  -moz-animation: bg-scrolling-reverse 1.92s infinite; /* Fx 5+ */
  -o-animation: bg-scrolling-reverse 1.92s infinite; /* Opera 12+ */
  animation: bg-scrolling-reverse 1.92s infinite; /* IE 10+ */
  -webkit-animation-timing-function: linear;
  -moz-animation-timing-function: linear;
  -o-animation-timing-function: linear;
  animation-timing-function: linear;
  border-top-left-radius: 1000px;
  border-top-right-radius: 1000px;
}
.divider {
  display: flex;
  border-top: #14181d outset 2px;
  border-bottom: #14181d outset 2px;
  height: 3em;
  width: 100vw;
  background: url('./tiles2.png') repeat 0 0;
}
.contact-me-container {
  margin-top: 2em;
  display: flex;
  width: inherit;
  max-width: inherit;
}
.contact-me-form {
  text-align: start;
  display: flex;
  flex-direction: column;
  width: inherit;
  max-width: inherit;
  font-size: 2rem;
}
.contact-me-form > input {
  border: #14181d outset 2px;
  background-color: #f2f2f2;
}
.contact-me-form > textarea {
  border: #14181d outset 2px;
  background-color: #f2f2f2;
}
.contact-me-form > .submit-button {
  cursor: pointer;
  text-align: center;
  display: flex;
  margin: 1em auto;
  background-color: #14181d;
}
/* RESUME BUTTON */
.resume {
  padding: 0;
  text-decoration: none;
  border: #14181d solid 2px;
  border-radius: 10px;
  padding: 0.5em 0.5em;
  margin: 5vh auto;
  background-color: #14181d;
  color: #7db5ff;
}
.resume:hover {
  color: #14181d;
  background-color: #e75151;
}
.contact-resume {
  margin-top: 1vh;
  margin-bottom: 1vh;
}

.resume-container {
  display: flex;
  align-items: center;
}
.resume-container > p {
  font-weight: bold;
  font-size: 2vw;
  margin: 0 0.5em 0 0.5em;
}
.resume-container > i {
  font-size: 2vw;
}

/* MAX-WIDTH */
@media screen and (max-width: 600px) {
  .nav-contact {
    margin: 2vw;
    font-size: 1.4rem;
  }
  .number {
    font-size: 1.4rem;
  }
  .number > i {
    margin: 1.5vw;
    width: 1.2em;
  }
  .contact-me-form {
    font-size: 1.4rem;
  }

  .card-img-overlay {
    font-size: 0.7rem;
  }
  .card-img-overlay > .card-title {
    font-size: 1.5rem;
  }
  .navbar-dark .navbar-nav .nav-link {
    font-size: 0.7rem;
    margin: 0 0.2vw;
  }
  .icon-container {
    font-size: 4em;
  }
  .modal-header > .icon-container {
    font-size: 1.5em;
  }
  .intro-resume {
    font-size: 1rem;
  }
  .intro-resume-container > p {
    font-size: 1rem;
  }
}

/* MAX-WIDTH */
@media screen and (max-width: 300px) {
  .nav-contact {
    margin: 1.5vw;
    font-size: 0.8rem;
  }
  .number {
    font-size: 0.8rem;
  }
  .number > i {
    margin: 1.5vw;
    width: 1.2em;
  }
  .contact-me-form {
    font-size: 0.8rem;
  }
  .navbar-dark .navbar-nav .nav-link {
    font-size: 0.5rem;
    margin: 0 0.25vw;
  }
  .icon-container {
    font-size: 2.5em;
  }
  .modal-header > .icon-container {
    font-size: 1em;
  }
  .intro-resume {
    font-size: 0.8rem;
  }
  .intro-resume-container > p {
    font-size: 0.8rem;
  }
}
/* MIN-WIDTH */
@media screen and (min-width: 1200px) {
  .nav-contact {
    margin: 2vw;
    font-size: 2rem;
  }
  .contact-me-form {
    font-size: 2rem;
  }
  .card-img-overlay {
    font-size: 1.3rem;
  }
  .card-img-overlay > .card-title {
    font-size: 3.2rem;
  }
  .nav-link {
    font-size: 2rem;
  }
  .icon-container {
    font-size: 4rem;
  }
  .intro-resume {
    font-size: 2rem;
  }
  .intro-resume-container > p {
    font-size: 2rem;
  }
}
